import logo from './logo.png';
import frase from './frase.png';
import buttonAccess from './buttonAccess.png'
import './App.css';
import React from 'react';

function App() {

    const altura = window.screen.height;
    const largura = window.screen.width;

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p className='App-accent'>
                    Seja muito bem-vindo(a) à
                </p>
                <div style={{ display: 'flex', flexDirection: 'column', padding: 20, marginTop: 26 }}>
                    <img src={frase} style={{ maxWidth: 345, maxHeight: 300 }} />
                </div>
                <iframe width={720} height={480} style={{ marginBottom: 100 }} frameBorder="0"
                src="https://player.vimeo.com/video/734513600?h=1f64d66e48">
                </iframe>
                {/* <div>
                    <h6 className='App-paragraph-2'>Vamos dar o próximo passo juntos?</h6>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: -20, justifyContent: 'space-around', width: '100%' }}>
                        <img src={buttonAccess} onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.bittareducacao.ijbaprendiz"} style={{ maxWidth: 500, maxHeight: 150, marginBottom: 100, cursor: 'pointer' }} alt="botão download para android" />
                    </div>
                </div> */}
            </header>
        </div>
    );
}

export default App;
